import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MasterState, MasterItem } from "@lib/store/types";

const initialState: MasterState = {
  data: [],
  error: "",
  loading: true,
};

const citySectorSlice = createSlice({
  name: "citySector",
  initialState,
  reducers: {
    loadCitySector: () => initialState,
    loadCitySectorSuccess: (
      state,
      { payload }: PayloadAction<MasterItem[]>
    ) => ({
      ...state,
      data: payload,
      loading: false,
    }),
    loadCitySectorError: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      loading: false,
      error: payload,
    }),
  },
});

export const { loadCitySector, loadCitySectorSuccess, loadCitySectorError } =
  citySectorSlice.actions;

export default citySectorSlice.reducer;
