import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MasterItem, MasterStatePension } from "@lib/store/types";

const initialState: MasterStatePension = {
  dataPension: [],
  error: "",
  loading: true,
};

const pensionSlice = createSlice({
  name: "Pension",
  initialState,
  reducers: {
    loadPension: () => initialState,
    loadPensionSuccess: (
      state,
      { payload }: PayloadAction<MasterItem[]>
    ) => ({
      ...state,
      dataPension: payload,
      loading: false,
    }),
    loadPensionError: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      loading: false,
      error: payload,
    }),
  },
});

export const {
  loadPension,
  loadPensionSuccess,
  loadPensionError,
} = pensionSlice.actions;

export default pensionSlice.reducer;
