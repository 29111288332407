import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  MasterItem,
  MasterStateAffiliationCompensationFund,
} from "@lib/store/types";

const initialState: MasterStateAffiliationCompensationFund = {
  dataAffiliationCompensationFund: [],
  error: "",
  loading: true,
};

const affiliationCompensationFundSlice = createSlice({
  name: "affiliationCompensationFund",
  initialState,
  reducers: {
    loadAffiliationCompensationFund: () => initialState,
    loadAffiliationCompensationFundSuccess: (
      state,
      { payload }: PayloadAction<MasterItem[]>
    ) => ({
      ...state,
      dataAffiliationCompensationFund: payload,
      loading: false,
    }),
    loadAffiliationCompensationFundError: (
      state,
      { payload }: PayloadAction<string>
    ) => ({
      ...state,
      loading: false,
      error: payload,
    }),
  },
});

export const {
  loadAffiliationCompensationFund,
  loadAffiliationCompensationFundSuccess,
  loadAffiliationCompensationFundError,
} = affiliationCompensationFundSlice.actions;

export default affiliationCompensationFundSlice.reducer;
