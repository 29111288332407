import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  MasterItem,
  MasterStateTypeVincuCompensationFund,
} from "@lib/store/types";

const initialState: MasterStateTypeVincuCompensationFund = {
  dataTypeVincuCompensationFund: [],
  error: "",
  loading: true,
};

const TypeVincuCompensationFundSlice = createSlice({
  name: "TypeVincuCompensationFund",
  initialState,
  reducers: {
    loadTypeVincuCompensationFund: () => initialState,
    loadTypeVincuCompensationFundSuccess: (
      state,
      { payload }: PayloadAction<MasterItem[]>
    ) => ({
      ...state,
      dataTypeVincuCompensationFund: payload,
      loading: false,
    }),
    loadTypeVincuCompensationFundError: (
      state,
      { payload }: PayloadAction<string>
    ) => ({
      ...state,
      loading: false,
      error: payload,
    }),
  },
});

export const {
  loadTypeVincuCompensationFund,
  loadTypeVincuCompensationFundSuccess,
  loadTypeVincuCompensationFundError,
} = TypeVincuCompensationFundSlice.actions;

export default TypeVincuCompensationFundSlice.reducer;
