import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MasterItem, MasterStateVulnerabilityFactor } from "@lib/store/types";

const initialState: MasterStateVulnerabilityFactor = {
  dataVulnerabilityFactor: [],
  error: "",
  loading: true,
};

const VulnerabilityFactorSlice = createSlice({
  name: "VulnerabilityFactor",
  initialState,
  reducers: {
    loadVulnerabilityFactor: () => initialState,
    loadVulnerabilityFactorSuccess: (
      state,
      { payload }: PayloadAction<MasterItem[]>
    ) => ({
      ...state,
      dataVulnerabilityFactor: payload,
      loading: false,
    }),
    loadVulnerabilityFactorError: (
      state,
      { payload }: PayloadAction<string>
    ) => ({
      ...state,
      loading: false,
      error: payload,
    }),
  },
});

export const {
  loadVulnerabilityFactor,
  loadVulnerabilityFactorSuccess,
  loadVulnerabilityFactorError,
} = VulnerabilityFactorSlice.actions;

export default VulnerabilityFactorSlice.reducer;
