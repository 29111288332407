import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MasterItem, MasterStateEducationalLevel } from "@lib/store/types";

const initialState: MasterStateEducationalLevel = {
  dataEducationalLevel: [],
  error: "",
  loading: true,
};

const educationalLevelSlice = createSlice({
  name: "educationalLevel",
  initialState,
  reducers: {
    loadEducationalLevel: () => initialState,
    loadEducationalLevelSuccess: (
      state,
      { payload }: PayloadAction<MasterItem[]>
    ) => ({
      ...state,
      dataEducationalLevel: payload,
      loading: false,
    }),
    loadEducationalLevelError: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      loading: false,
      error: payload,
    }),
  },
});

export const {
  loadEducationalLevel,
  loadEducationalLevelSuccess,
  loadEducationalLevelError,
} = educationalLevelSlice.actions;

export default educationalLevelSlice.reducer;
