import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MasterItem, MasterStateIndigenousVillage } from "@lib/store/types";

const initialState: MasterStateIndigenousVillage = {
  dataIndigenousVillage: [],
  error: "",
  loading: true,
};

const indigenousVillageSlice = createSlice({
  name: "indigenousVillage",
  initialState,
  reducers: {
    loadIndigenousVillage: () => initialState,
    loadIndigenousVillageSuccess: (
      state,
      { payload }: PayloadAction<MasterItem[]>
    ) => ({
      ...state,
      dataIndigenousVillage: payload,
      loading: false,
    }),
    loadIndigenousVillageError: (
      state,
      { payload }: PayloadAction<string>
    ) => ({
      ...state,
      loading: false,
      error: payload,
    }),
  },
});

export const {
  loadIndigenousVillage,
  loadIndigenousVillageSuccess,
  loadIndigenousVillageError,
} = indigenousVillageSlice.actions;

export default indigenousVillageSlice.reducer;
