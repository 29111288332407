import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MasterItem, MasterStateReceptionCenter} from "@lib/store/types";

const initialState: MasterStateReceptionCenter = {
  dataReceptionCenter: [],
  error: "",
  loading: true,
};

const ReceptionCenterSlice = createSlice({
  name: "receptionCenter",
  initialState,
  reducers: {
    loadReceptionCenter: () => initialState,
    loadReceptionCenterSuccess: (
      state,
      { payload }: PayloadAction<MasterItem[]>
    ) => ({
      ...state,
      dataReceptionCenter: payload,
      loading: false,
    }),
    loadReceptionCenterError: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      loading: false,
      error: payload,
    }),
  },
});

export const {
  loadReceptionCenter,
  loadReceptionCenterSuccess,
  loadReceptionCenterError,
} = ReceptionCenterSlice.actions;

export default ReceptionCenterSlice.reducer;
