import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MasterItem, MasterStatePopulationType } from "@lib/store/types";

const initialState: MasterStatePopulationType = {
  dataPopulationType: [],
  error: "",
  loading: true,
};

const PopulationTypeSlice = createSlice({
  name: "PopulationType",
  initialState,
  reducers: {
    loadPopulationType: () => initialState,
    loadPopulationTypeSuccess: (
      state,
      { payload }: PayloadAction<MasterItem[]>
    ) => ({
      ...state,
      dataPopulationType: payload,
      loading: false,
    }),
    loadPopulationTypeError: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      loading: false,
      error: payload,
    }),
  },
});

export const {
  loadPopulationType,
  loadPopulationTypeSuccess,
  loadPopulationTypeError,
} = PopulationTypeSlice.actions;

export default PopulationTypeSlice.reducer;
