import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MasterItem, MasterState } from "@lib/store/types";

const initialState: MasterState = {
  data: [],
  error: "",
  loading: true,
};

const mainRoadSlice = createSlice({
  name: "mainRoad",
  initialState,
  reducers: {
    loadMainRoad: () => initialState,
    loadMainRoadSuccess: (state, { payload }: PayloadAction<MasterItem[]>) => ({
      ...state,
      data: payload,
      loading: false,
    }),
    loadMainRoadError: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      loading: false,
      error: payload,
    }),
  },
});

export const { loadMainRoad, loadMainRoadSuccess, loadMainRoadError } =
  mainRoadSlice.actions;

export default mainRoadSlice.reducer;
