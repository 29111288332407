import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MasterItem, MasterStateGender } from "@lib/store/types";

const initialState: MasterStateGender = {
  dataGender: [],
  error: "",
  loading: true,
};

const genderSlice = createSlice({
  name: "gender",
  initialState,
  reducers: {
    loadGenders: () => initialState,
    loadGendersSuccess: (state, { payload }: PayloadAction<MasterItem[]>) => ({
      ...state,
      dataGender: payload,
      loading: false,
    }),
    loadGendersError: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      loading: false,
      error: payload,
    }),
  },
});

export const { loadGenders, loadGendersSuccess, loadGendersError } =
  genderSlice.actions;

export default genderSlice.reducer;
