import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MasterItem, MasterStateEthnicGroupFomento } from "@lib/store/types";

const initialState: MasterStateEthnicGroupFomento = {
  dataEthnicGroupFomento: [],
  error: "",
  loading: true,
};

const EthnicGroupFomentoSlice = createSlice({
  name: "EthnicGroup",
  initialState,
  reducers: {
    loadEthnicGroupFomento: () => initialState,
    loadEthnicGroupFomentoSuccess: (
      state,
      { payload }: PayloadAction<MasterItem[]>
    ) => ({
      ...state,
      dataEthnicGroupFomento: payload,
      loading: false,
    }),
    loadEthnicGroupFomentoError: (
      state,
      { payload }: PayloadAction<string>
    ) => ({
      ...state,
      loading: false,
      error: payload,
    }),
  },
});

export const {
  loadEthnicGroupFomento,
  loadEthnicGroupFomentoSuccess,
  loadEthnicGroupFomentoError,
} = EthnicGroupFomentoSlice.actions;

export default EthnicGroupFomentoSlice.reducer;
