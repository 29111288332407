import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  VulnerabilityConditionItem,
  VulnerabilityConditionState,
} from "../types";

const initialState: VulnerabilityConditionState = {
  dataVulnerabilityCondition: [],
  loading: true,
};

const vulnerabilityConditionSlice = createSlice({
  name: "VulnerabilityConditions",
  initialState,
  reducers: {
    loadVulnerabilityConditions: () => initialState,
    loadVulnerabilityConditionsSuccess: (
      state,
      { payload }: PayloadAction<VulnerabilityConditionItem[]>
    ) => ({
      ...state,
      dataVulnerabilityCondition: payload,
      loading: false,
    }),
    loadVulnerabilityConditionError: (
      state,
      { payload }: PayloadAction<string>
    ) => ({
      ...state,
      loading: false,
      error: payload,
    }),
  },
});

export const {
  loadVulnerabilityConditions,
  loadVulnerabilityConditionsSuccess,
  loadVulnerabilityConditionError,
} = vulnerabilityConditionSlice.actions;

export default vulnerabilityConditionSlice.reducer;
