import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OffDaysItem, OffDaysState } from "../types";

const initialState: OffDaysState = {
    dataOffDays: [],
    loading: true,
    messageError: ""
}

const offDaysSlice = createSlice({
    name: "offDays",
    initialState,
    reducers:{
        loadOffDays: () => initialState,
        loadOffDaysSuccess: (
            state,
            {payload}: PayloadAction<OffDaysItem[]>
        ) => ({
            ...state,
            dataOffDays: payload
        }),
        loadOffDaysError: (
            state,
            {payload}: PayloadAction<string>
        ) => ({
            ...state,
            messageError: payload
        })
    }
})

export const { 
    loadOffDays, 
    loadOffDaysSuccess, 
    loadOffDaysError 
} = offDaysSlice.actions;

export default offDaysSlice.reducer;