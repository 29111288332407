import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  MasterScheduleItem,
  MasterStateScheduleAssignments,
} from "@lib/store/types";

const initialState: MasterStateScheduleAssignments = {
  dataSchedule: [],
  error: "",
  timeDate: "",
  offDay: false,
  monthSelect: "",
  daysWithoutSchedule: [],
  unavailable: [],
  loading: true,
};

const scheduleAssignmentsSlice = createSlice({
  name: "scheduleAssignments",
  initialState,
  reducers: {
    loadScheduleAssignments: () => initialState,
    loadScheduleAssignmentsDate: (
      state,
      { payload }: PayloadAction<string>
    ) => ({
      ...state,
      timeDate: payload,
      loading: true,
    }),
    loadScheduleMonthSelect: (
      state,
      {payload}: PayloadAction<string>
    ) => ({
      ...state,
      monthSelect: payload
    }),
    loadScheduleMonthSelectSuccess: (
      state,
      {payload}: PayloadAction<Date[]>
    ) => ({
      ...state,
      daysWithoutSchedule: payload
    }),
    loadScheduleAssignmentsSuccess: (
      state,
      {
        payload,
      }: PayloadAction<{
        data: MasterScheduleItem[];
        days: [];
        OffDays: boolean;
      }>
    ) => ({
      ...state,
      dataSchedule: payload.data,
      unavailable: payload.days,
      offDay: payload.OffDays,
      loading: false,
    }),
    loadScheduleAssignmentsError: (
      state,
      { payload }: PayloadAction<string>
    ) => ({
      ...state,
      loading: false,
      error: payload,
    }),
    cleanDataSchedule: (
      state
    ) => ({
      ...state,
      dataSchedule: []
    }),
  },
});

export const {
  loadScheduleAssignments,
  loadScheduleMonthSelect,
  loadScheduleMonthSelectSuccess,
  loadScheduleAssignmentsSuccess,
  loadScheduleAssignmentsError,
  loadScheduleAssignmentsDate,
  cleanDataSchedule
} = scheduleAssignmentsSlice.actions;

export default scheduleAssignmentsSlice.reducer;
