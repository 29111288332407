import createSagaMiddleware from "redux-saga";
import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "@lib/store/slices";
export const sagaMiddleware = createSagaMiddleware();
import rootSaga from "@lib/store/sagas";

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware(),
    sagaMiddleware,
  ],
  devTools: true,
});
sagaMiddleware.run(rootSaga);
