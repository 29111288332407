import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AuthState } from "@lib/store/types/index";

export const initialState: AuthState = {
  currentUser: {},
  accessToken: "",
  isAuthenticated: false,
  error: "",
  loadingAuth: true,
  isAffiliateValid: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, { payload }: PayloadAction<any>) => {
      state.accessToken = payload;
      state.loadingAuth = true;
      state.isAuthenticated = false;
    },
    refreshLogin: (state, { payload }: PayloadAction<any>) => {
      state.accessToken = payload;
    },
    loginSuccess: (state, { payload }: PayloadAction<any>) => {
      state.currentUser = payload;
      (state.error = ""), (state.isAuthenticated = true);
      state.loadingAuth = false;
    },
    loginError: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.isAuthenticated = false;
      state.loadingAuth = false;
    },
    logout: (state) => {
      state.loadingAuth = true;
    },
    logoutSuccess: (state) => {
      state.isAuthenticated = false;
      state.currentUser = {};
      state.accessToken = "";
      state.error = "";
      state.loadingAuth = false;
    },
    loadValidationAffiliate: (state) => ({
      ...state,
      loading: false,
    }),
    loadValidationAffiliateSuccess: (
      state,
      { payload }: PayloadAction<string>
    ) => ({
      ...state,
      isAffiliateValid: payload,
      loading: false,
    }),
    loadValidationAffiliateError: (
      state,
      { payload }: PayloadAction<string>
    ) => ({
      ...state,
      error: payload,
      loading: false,
    }),
    updateLoadingAuth: (state) => ({
      ...state,
      loadingAuth: false,
    }),
  },
});

export const {
  login,
  refreshLogin,
  loginSuccess,
  loginError,
  logout,
  logoutSuccess,
  loadValidationAffiliate,
  loadValidationAffiliateSuccess,
  loadValidationAffiliateError,
  updateLoadingAuth,
} = authSlice.actions;

export default authSlice.reducer;
