import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MasterItem, MasterStateReasonNotPaymentPension } from "@lib/store/types/index";

const initialState: MasterStateReasonNotPaymentPension = {
  dataReasonNotPaymentPension: [],
  error: "",
  loading: true,
};

const reasonNotPaymentPensionSlice = createSlice({
  name: "reasonNotPaymentPension",
  initialState,
  reducers: {
    loadReasonNotPaymentPension: () => initialState,
    loadReasonNotPaymentPensionSuccess: (
      state,
      { payload }: PayloadAction<MasterItem[]>
    ) => ({
      ...state,
      dataReasonNotPaymentPension: payload,
      loading: false,
    }),
    loadReasonNotPaymentPensionError: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      loading: false,
      error: payload,
    }),
  },
});

export const { loadReasonNotPaymentPension, loadReasonNotPaymentPensionSuccess, loadReasonNotPaymentPensionError } =
  reasonNotPaymentPensionSlice.actions;

export default reasonNotPaymentPensionSlice.reducer;
