import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MasterItem, MasterStateEarnedSalaries } from "@lib/store/types";

const initialState: MasterStateEarnedSalaries = {
  dataEarnedSalaries: [],
  error: "",
  loading: true,
};

const earnedSalariesSlice = createSlice({
  name: "earnedSalaries",
  initialState,
  reducers: {
    loadEarnedSalaries: () => initialState,
    loadEarnedSalariesSuccess: (
      state,
      { payload }: PayloadAction<MasterItem[]>
    ) => ({
      ...state,
      dataEarnedSalaries: payload,
      loading: false,
    }),
    loadEarnedSalariesError: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      loading: false,
      error: payload,
    }),
  },
});

export const {
  loadEarnedSalaries,
  loadEarnedSalariesSuccess,
  loadEarnedSalariesError,
} = earnedSalariesSlice.actions;

export default earnedSalariesSlice.reducer;
