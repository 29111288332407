import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MasterItem, MasterStateIndigenousReservation } from "@lib/store/types";

const initialState: MasterStateIndigenousReservation = {
  dataIndigenousReservation: [],
  error: "",
  loading: true,
};

const indigenousReservationSlice = createSlice({
  name: "indigenousReservation",
  initialState,
  reducers: {
    loadIndigenousReservation: () => initialState,
    loadIndigenousReservationSuccess: (
      state,
      { payload }: PayloadAction<MasterItem[]>
    ) => ({
      ...state,
      dataIndigenousReservation: payload,
      loading: false,
    }),
    loadIndigenousReservationError: (
      state,
      { payload }: PayloadAction<string>
    ) => ({
      ...state,
      loading: false,
      error: payload,
    }),
  },
});

export const {
  loadIndigenousReservation,
  loadIndigenousReservationSuccess,
  loadIndigenousReservationError,
} = indigenousReservationSlice.actions;

export default indigenousReservationSlice.reducer;
