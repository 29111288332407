import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MasterItem, MasterStatePensionsFund } from "@lib/store/types";

const initialState: MasterStatePensionsFund = {
  dataPensionsFund: [],
  error: "",
  loading: true,
};

const PensionsFundSlice = createSlice({
  name: "PensionsFund",
  initialState,
  reducers: {
    loadPensionsFund: () => initialState,
    loadPensionsFundSuccess: (
      state,
      { payload }: PayloadAction<MasterItem[]>
    ) => ({
      ...state,
      dataPensionsFund: payload,
      loading: false,
    }),
    loadPensionsFundError: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      loading: false,
      error: payload,
    }),
  },
});

export const {
  loadPensionsFund,
  loadPensionsFundSuccess,
  loadPensionsFundError,
} = PensionsFundSlice.actions;

export default PensionsFundSlice.reducer;
