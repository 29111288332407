import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MasterItem, MasterStateEthnicGroup } from "@lib/store/types";

const initialState: MasterStateEthnicGroup = {
  dataEthnicGroup: [],
  error: "",
  loading: true,
};

const EthnicGroupSlice = createSlice({
  name: "EthnicGroup",
  initialState,
  reducers: {
    loadEthnicGroup: () => initialState,
    loadEthnicGroupSuccess: (
      state,
      { payload }: PayloadAction<MasterItem[]>
    ) => ({
      ...state,
      dataEthnicGroup: payload,
      loading: false,
    }),
    loadEthnicGroupError: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      loading: false,
      error: payload,
    }),
  },
});

export const { loadEthnicGroup, loadEthnicGroupSuccess, loadEthnicGroupError } =
  EthnicGroupSlice.actions;

export default EthnicGroupSlice.reducer;
