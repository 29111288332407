import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DocumentationState, DocumentItem, PostulationMode } from "../types";
import { emptyPostulationMode } from "@lib/utils/helpers";

const initialState: DocumentationState = {
  postulationDocs: [],
  documentType: "",
  documentRecordId: "",
  selectPostulationMode: emptyPostulationMode(),
  existsDocument: false,
  error: "",
  loading: true,
};

const documentationSlice = createSlice({
  name: "documentation",
  initialState,
  reducers: {
    loadDocumentation: () => initialState,
    loadDocumentationSuccess: (
      state,
      { payload }: PayloadAction<Array<DocumentItem>>
    ) => ({
      ...state,
      postulationDocs: payload,
      loading: false,
    }),
    loadDocumentationError: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      loading: false,
      error: payload,
    }),
    loadDocumentByType: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      documentType: payload,
      loading: true,
    }),
    loadDocumentByTypeSuccess: (
      state,
      { payload }: PayloadAction<{ id: string; exists: boolean }>
    ) => ({
      ...state,
      documentRecordId: payload.id,
      existsDocument: payload.exists,
      loading: false,
    }),
    loadDocumentByTypeError: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      loading: false,
      error: payload,
    }),
    deleteDocumentPostulation: (state) => {},
    deleteDocumentPostulationSuccess: (
      state,
      { payload }: PayloadAction<boolean>
    ) => ({
      ...state,
      existsDocument: payload,
      documentRecordId: "",
      selectPostulationMode: emptyPostulationMode(),
      loading: false,
    }),
    deleteDocumentPostulationError: (
      state,
      { payload }: PayloadAction<string>
    ) => ({
      ...state,
      loading: false,
      error: payload,
    }),
    loadSelectPostulationMode: (
      state,
      { payload }: PayloadAction<PostulationMode>
    ) => ({
      ...state,
      selectPostulationMode: payload,
      loading: false,
    }),
  },
});

export const {
  loadDocumentation,
  loadDocumentationSuccess,
  loadDocumentationError,
  loadDocumentByType,
  loadDocumentByTypeSuccess,
  loadDocumentByTypeError,
  deleteDocumentPostulation,
  deleteDocumentPostulationSuccess,
  deleteDocumentPostulationError,
  loadSelectPostulationMode,
} = documentationSlice.actions;

export default documentationSlice.reducer;
