import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MasterItem, MasterStateGenderFomento } from "@lib/store/types";

const initialState: MasterStateGenderFomento = {
  dataGenderFomento: [],
  error: "",
  loading: true,
};

const genderFomentoSlice = createSlice({
  name: "genderFomento",
  initialState,
  reducers: {
    loadGendersFomento: () => initialState,
    loadGendersFomentoSuccess: (
      state,
      { payload }: PayloadAction<MasterItem[]>
    ) => ({
      ...state,
      dataGenderFomento: payload,
      loading: false,
    }),
    loadGendersFomentoError: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      loading: false,
      error: payload,
    }),
  },
});

export const {
  loadGendersFomento,
  loadGendersFomentoSuccess,
  loadGendersFomentoError,
} = genderFomentoSlice.actions;

export default genderFomentoSlice.reducer;
