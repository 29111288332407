import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  MasterItem,
  MasterStateTpDocumentFomento,
} from "@lib/store/types/index";

const initialState: MasterStateTpDocumentFomento = {
  dataTpDocumentFomento: [],
  error: "",
  loading: true,
};

const typeDocumentsFomentoSlice = createSlice({
  name: "typeDocumentsFomento",
  initialState,
  reducers: {
    loadTypeDocumentsFomento: () => initialState,
    loadTypeDocumentsFomentoSuccess: (
      state,
      { payload }: PayloadAction<MasterItem[]>
    ) => ({
      ...state,
      dataTpDocumentFomento: payload,
      loading: false,
    }),
    loadTypeDocumentsFomentoError: (
      state,
      { payload }: PayloadAction<string>
    ) => ({
      ...state,
      loading: false,
      error: payload,
    }),
  },
});

export const {
  loadTypeDocumentsFomento,
  loadTypeDocumentsFomentoSuccess,
  loadTypeDocumentsFomentoError,
} = typeDocumentsFomentoSlice.actions;

export default typeDocumentsFomentoSlice.reducer;
