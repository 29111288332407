import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BusinessDay, PostulationBusinessDaysState } from "@lib/store/types";

const initialState: PostulationBusinessDaysState = {
  postulationType: "",
  businessDays: [],
  error: "",
  loading: true,
};

const postulationBusinessDaysSlice = createSlice({
  name: "postulationBusinessDays",
  initialState,
  reducers: {
    loadPostulationBusinessDays: () => initialState,
    loadPostulationBusinessDaysSuccess: (
      state,
      { payload }: PayloadAction<BusinessDay[]>
    ) => ({
      ...state,
      businessDays: payload,
      loading: false,
    }),
    loadPostulationBusinessDaysError: (
      state,
      { payload }: PayloadAction<string>
    ) => ({
      ...state,
      loading: false,
      error: payload,
    }),
  },
});

export const {
  loadPostulationBusinessDays,
  loadPostulationBusinessDaysSuccess,
  loadPostulationBusinessDaysError,
} = postulationBusinessDaysSlice.actions;

export default postulationBusinessDaysSlice.reducer;
