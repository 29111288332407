import { login } from "@lib/store/slices/auth";
import type { KeycloakInstance } from "keycloak-js";
import {
  Age,
  DocumentType,
  DocumentTypes,
  DocumentTypesFomento,
  Gender,
  GenderFomento,
} from "@lib/utils/constants";
import {
  differenceInYears,
  differenceInDays,
  parseISO,
  differenceInMonths,
  addMonths,
  isBefore,
  parse,
  format,
} from "date-fns";

import moment from "moment";
require("moment/locale/es");

import {
  MasterItem,
  DocumentItem,
  FormOneItem,
  MasterFormTwo,
  PostulationFormItem,
  FormMenuItem,
  FormThreeItem,
  Availability,
  FormSchedulingItem,
  MasterDocumentTypes,
  FormMenuAccordion,
  MasterItemSideMenu,
  PostulationMode,
} from "@lib/store/types/index";

import { Dispatch } from "react";
import { Action } from "redux";

import { useSelector } from "react-redux";
import { getPostulation } from "@lib/store/selectors";
import { loadingSpinnerButton } from "@lib/store/slices/navigation";

export const emptyPostulation = (): PostulationFormItem => ({
  formOne: emptyFormOne(),
  formMenu: emptyFormMenu(),
  formThree: emptyFormThree(),
  formMenuAccordion: emptyMenuAccordion(),
  sideMenu: dataSideMenu(),
});

export const emptyFormMenu = (): FormMenuItem => ({
  statePensionYesIHave: false,
  statePensionDontHave: false,
  stateUnemploymentSubsidyYesIHave: false,
  stateUnemploymentSubsidyDontHave: false,
  statePublicEmploymentYesIHave: false,
  statePublicEmploymentDontHave: false,
  stateSeveranceFundYesIHave: false,
  stateSeveranceFundDontHave: false,
  stateSeveranceProtectionYesIHave: false,
  stateSeveranceProtectionDontHave: false,
  statePrivacyPoliciesCheck: false,
  stateSwornStatementCheck: false,
  stateNotificationCheck: false,
});

export const emptyFormOne = (): FormOneItem => ({
  postulateMode: { id: "", name: "" },
  headName: "",
  headLastName: "",
  headBirthDate: "",
  headAge:"",
  headTpDocument: { id: "", name: "" },
  headNumberIdentification: 0,
  headCivilStatus: { id: "", name: "" },
  headGender: { id: "", name: "" },
  homeAddress: "",
  homeNeighborhood: "",
  homeLocation: { id: "", label: "" },
  homePhoneOne: "",
  homePhoneTwo: "",
  email: "",
  receptionCenter: { id: "", name: "" },
  ethnicGroup: { id: "", name: "" },
  typeResidence: { id: "", name: "" },
  highestGradeSchooling: { id: "", name: "" },
  populationType: { id: "", name: "" },
  vulnerabilityFactor: { id: "", name: "" },
  headLastCompany: "",
  headWithdrawalDate: "",
  headLastSalary: 0,
  headLastEps: { id: "", name: "" },
  headLastCompensationFund: { id: "", name: "" },
  headTypeConnectionCompensationFund: { id: "", name: "" },
  monthsUnemployment: "",
  dateUnemployment: "",
  indigenousReservation: { id: "", name: "" },
  indigenousVillage: { id: "", name: "" },
  publicEmployment: { id: "", name: "" },
  employmentService: { id: "", name: "" },
  typeDisability: { id: "", name: "" },
  commune: { id: "", name: "" },
  stratum: { id: "", name: "" },
  stateEmployability: { id: "", name: "" },
  formations:{ id: "", name: "" },
});

export const emptyFormThree = (): FormThreeItem => ({
  affiliationPensionEntity: { id: "", name: "" },
  whyNotRequirePension: { id: "", name: "" },
  severanceFund: { id: "", name: "" },
  minimumAmountSeverance: 0,
  requiresPensionPayment: { id: "", name: "" },
  haveSeveranceFund: { id: "", name: "" },
  saveProtectionMechanism: { id: "", name: "" },
});

export const getInfoDocumentsInformationPersonal = (
  initialValues: FormOneItem
) => {
  const documentsRequired = {
    identityDocument: true,
    LaborLetter: false,
    CertificateOfIncome: false,
    PensionResolution: false,
    DamnedNaturalDisaster: false,
    displacedSocialAction: false,
    relocationHighRiskArea: false,
    RelocatedSanAndresProvidenciaSantaCatalina: false,
    TerroristAttacksVictim: false,
    specialConditionDisability: false,
    specialConditionICBF: false,
    specialConditionAfro: false,
    specialConditionIndigenous: false,
  };
  return documentsRequired;
};

export const emptyFormTwo = (): MasterFormTwo => ({
  formItem: [],
});

export function _loadingSpinnerButton(
  dispatch: Dispatch<Action>,
  value: boolean
) {
  dispatch(loadingSpinnerButton(value));
}

export const getApiDocumentType = (key: string): MasterItem | undefined => {
  if (key === DocumentTypes.IdentificationCard.abbreviation)
    return {
      id: DocumentTypes.IdentificationCard.id,
      name: DocumentTypes.IdentificationCard.name,
    };
  if (key === DocumentTypes.ForeignerIdentification.abbreviation)
    return {
      id: DocumentTypes.ForeignerIdentification.id,
      name: DocumentTypes.ForeignerIdentification.name,
    };
  if (key === DocumentTypes.MinorIdentification.abbreviation)
    return {
      id: DocumentTypes.MinorIdentification.id,
      name: DocumentTypes.MinorIdentification.name,
    };
  if (key === DocumentTypes.Passport.abbreviation)
    return { id: DocumentTypes.Passport.id, name: DocumentTypes.Passport.name };
  if (key === DocumentTypes.SpecialResidencePermission.abbreviation)
    return {
      id: DocumentTypes.SpecialResidencePermission.id,
      name: DocumentTypes.SpecialResidencePermission.name,
    };
    if (key === DocumentTypes.TemporaryProtection.abbreviation)
      return {
        id: DocumentTypes.TemporaryProtection.id,
        name: DocumentTypes.TemporaryProtection.name,
      };
};

export const getApiDocumentTypeFomento = (
  key: string
): MasterItem | undefined => {
  if (key === DocumentTypesFomento.IdentificationCard.abbreviation)
    return {
      id: DocumentTypesFomento.IdentificationCard.id,
      name: DocumentTypesFomento.IdentificationCard.abbreviation,
    };

  if (key === DocumentTypesFomento.ForeignerIdentification.abbreviation)
    return {
      id: DocumentTypesFomento.ForeignerIdentification.id,
      name: DocumentTypesFomento.ForeignerIdentification.abbreviation,
    };

  if (key === DocumentTypesFomento.MinorIdentification.abbreviation)
    return {
      id: DocumentTypesFomento.MinorIdentification.id,
      name: DocumentTypesFomento.MinorIdentification.abbreviation,
    };

  if (key === DocumentTypesFomento.DocumentNumberIdentification.abbreviation)
    return {
      id: DocumentTypesFomento.DocumentNumberIdentification.id,
      name: DocumentTypesFomento.DocumentNumberIdentification.abbreviation,
    };

  if (key === DocumentTypesFomento.PermitTemporaryProtection.abbreviation)
    return {
      id: DocumentTypesFomento.PermitTemporaryProtection.id,
      name: DocumentTypesFomento.PermitTemporaryProtection.abbreviation,
    };
};

export const getApiDocumentTypeAbbreviation = (key: string): MasterItem | undefined  => {
  if (key === DocumentTypesFomento.IdentificationCard.name)
    return {
      id: DocumentTypesFomento.IdentificationCard.id,
      name: DocumentTypesFomento.IdentificationCard.abbreviation,
    };

  if (key === DocumentTypesFomento.ForeignerIdentification.name)
    return {
      id: DocumentTypesFomento.ForeignerIdentification.id,
      name: DocumentTypesFomento.ForeignerIdentification.abbreviation,
    };

  if (key === DocumentTypesFomento.MinorIdentification.name)
    return {
      id: DocumentTypesFomento.MinorIdentification.id,
      name: DocumentTypesFomento.MinorIdentification.abbreviation,
    };

  if (key === DocumentTypesFomento.DocumentNumberIdentification.name)
    return {
      id: DocumentTypesFomento.DocumentNumberIdentification.id,
      name: DocumentTypesFomento.DocumentNumberIdentification.abbreviation,
    };

  if (key === DocumentTypesFomento.PermitTemporaryProtection.name)
    return {
      id: DocumentTypesFomento.PermitTemporaryProtection.id,
      name: DocumentTypesFomento.PermitTemporaryProtection.abbreviation,
    };
}

export const getApiGenderValue = (genderValue: MasterItem) => {
  if(genderValue.name === Gender.Man.name){
    return{
      id: GenderFomento.Man.id,
      name: GenderFomento.Man.name,
    }
  }
  if(genderValue.name === Gender.Woman.name){
    return{
      id: GenderFomento.Woman.id,
      name: GenderFomento.Woman.name,
    }
  }
  else{
    return{
      id: GenderFomento.Other.id,
      name: GenderFomento.Other.name,
    }
  }
}

export const formatData = (data: any, array: any) => {
  data.map((item: any) => {
    array.push({ label: item.name, value: item.id });
  });
  return array;
};

export function formatDatePostulation(datePostulation: string) {
  const date = parse(datePostulation, "yyyy-MM-dd HH:mm:ss", new Date());
  return format(date, "yyyy-MM-dd");
}

export const formatDateInput = (date: string) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const setStreetCompany = (street: string): string => {
  if (!street || street.length === 0) {
    return "";
  }

  return street;
};

export const convertDate = (dateString: string) => {
  const newDateString = dateString.replace(/-/g, "");
  const year = +newDateString.substring(0, 4);
  const month = +newDateString.substring(4, 6);
  const day = +newDateString.substring(6, 8);

  const convertedDate = new Date(year, month - 1, day);
  return convertedDate;
};

export const formatDate = (date: string, iso = false) => {
  const fullDate = new Date(iso ? date : date.replace("-", "/"));
  const registerDate = moment(iso ? date : date.replace("-", "/"));

  const meses = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Setiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  const day = fullDate.getUTCDate(); // Obtener día del mes en UTC meses[registerDate.month()];

  return `${registerDate.date()} de ${registerDate.format(
    "MMMM"
  )} de ${registerDate.year()}`;
};

export const formatDateTime = (dateTimeStr: string) => {
  // Parse la cadena de fecha y hora en un objeto Date
  const dateTime = new Date(dateTimeStr);

  // Obtiene la hora y los minutos
  const hours = dateTime.getHours();
  const minutes = dateTime.getMinutes();
  // Determina si es AM o PM
  const amOrPm = hours >= 12 ? "PM" : "AM";
  // Convierte la hora al formato de 12 horas
  const formattedHours = hours % 12 || 12;
  // Formatea la fecha en formato MM/DD/AAAA
  const formattedDate = `${
    dateTime.getMonth() + 1
  }/${dateTime.getDate()}/${dateTime.getFullYear()}`;
  // Formatea la fecha para que muestre el mes en caracteres
  const fullDate = formatDate(formattedDate);
  // Formatea la hora en formato HH:MM AM/PM
  const formattedTime = `${formattedHours}:${minutes
    .toString()
    .padStart(2, "0")} ${amOrPm}`;
  const date = `${fullDate} ${formattedTime}`;

  return date;
};
export const formatTime = (dateTimeStr: string) => {
  // Parsea la cadena de fecha y hora en un objeto Date (se asume que la cadena está en formato UTC)
  const dateTime = new Date(dateTimeStr);

  // Convierte la hora a UTC
  const utcHours = dateTime.getUTCHours();
  const utcMinutes = dateTime.getUTCMinutes();

  // Determina si es AM o PM en UTC
  const amOrPm = utcHours >= 12 ? "PM" : "AM";

  // Convierte las horas a formato de 12 horas en UTC
  const formattedHours = utcHours % 12 || 12;

  // Formatea la hora en formato HH:MM AM/PM
  const formattedTime = `${formattedHours}:${utcMinutes
    .toString()
    .padStart(2, "0")} ${amOrPm}`;
  const date = `${formattedTime}`;

  return date;
};

export const emptyDocument = (): DocumentItem => ({
  id: "",
  postulation: "",
  documentation_type: "",
  document_name: "",
  url_doc: "",
  dt_creation: null,
  dt_update: null,
  owner: "",
});

const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const getBoolean = (value: string): Boolean => {
  switch (value) {
    case "true":
    case "1":
    case "on":
    case "yes":
      return true;
    default:
      return false;
  }
};

export const Options = () => {
  const options = [
    { id: "Si", name: "Si" },
    { id: "No", name: "No" },
  ];
  const mergedoptions = [...options];
  return mergedoptions;
};

const isObject = (objValue: any) => {
  return (
    objValue && typeof objValue === "object" && objValue.constructor === Object
  );
};

export const getAge = (birthDate: string): number => {
  if (birthDate) {
    const birthday = new Date(birthDate);
    let age = differenceInYears(new Date(), birthday);
    return age;
  }
  return 0;
};

export const emptyAvailableFosfec = (): Availability => ({
  available: false,
});

export async function getServerKeycloack(
  dispatch: any,
  keycloak: KeycloakInstance | undefined
) {
  if (keycloak?.token != undefined) {
    dispatch(login(keycloak?.token));
  }
}

export const dataMissingStepOne = (
  postulationForm: PostulationFormItem
): boolean => {
  if (
    postulationForm.formOne.email.length < 1 ||
    postulationForm.formOne.email.trim() === "" ||
    postulationForm.formOne.headName.length < 1 ||
    postulationForm.formOne.headName.trim() === "" ||
    postulationForm.formOne.headNumberIdentification < 1 ||
    postulationForm.formOne.homePhoneOne.length < 1 ||
    postulationForm.formOne.homePhoneOne.trim() === "" ||
    postulationForm.formOne.postulateMode.id.length < 1 ||
    postulationForm.formOne.postulateMode.id.trim() === "" ||
    postulationForm.formOne.headLastName.length < 1 ||
    postulationForm.formOne.headLastName.trim() === "" ||
    postulationForm.formOne.headTpDocument.id.length < 1 ||
    postulationForm.formOne.headTpDocument.id.trim() === "" ||
    postulationForm.formOne.headGender.id.length < 1 ||
    postulationForm.formOne.headGender.id.trim() === "" ||
    postulationForm.formOne.headCivilStatus.id.length < 1 ||
    postulationForm.formOne.headCivilStatus.id.trim() === "" ||
    postulationForm.formOne.homeAddress.length < 1 ||
    postulationForm.formOne.homeAddress.trim() === "" ||
    postulationForm.formOne.homeLocation.id.length < 1 ||
    postulationForm.formOne.homeLocation.id.trim() === "" ||
    postulationForm.formOne.homeNeighborhood.length < 1 ||
    postulationForm.formOne.homeNeighborhood.trim() === "" ||
    postulationForm.formOne.receptionCenter.id.length < 1 ||
    postulationForm.formOne.receptionCenter.id.trim() === "" ||
    postulationForm.formOne.ethnicGroup.id.length < 1 ||
    postulationForm.formOne.ethnicGroup.id.trim() === "" ||
    postulationForm.formOne.typeResidence.id.length < 1 ||
    postulationForm.formOne.typeResidence.id.trim() === "" ||
    postulationForm.formOne.highestGradeSchooling.id.length < 1 ||
    postulationForm.formOne.highestGradeSchooling.id.trim() === "" ||
    postulationForm.formOne.populationType.id.length < 1 ||
    postulationForm.formOne.populationType.id.trim() === "" ||
    postulationForm.formOne.vulnerabilityFactor.id.length < 1 ||
    postulationForm.formOne.vulnerabilityFactor.id.trim() === "" ||
    postulationForm.formOne.headWithdrawalDate.length < 1 ||
    postulationForm.formOne.headWithdrawalDate.trim() === ""
  ) {
    return true;
  }
  return false;
};

export const dataMissingSocialSecurity = (
  postulationForm: PostulationFormItem
): boolean => {
  if (
    postulationForm.formOne.headLastEps.id.length < 1 ||
    postulationForm.formOne.headLastEps.id.trim() === "" ||
    postulationForm.formOne.headLastCompensationFund.id.length < 1 ||
    postulationForm.formOne.headLastCompensationFund.id.trim() === "" ||
    postulationForm.formOne.headTypeConnectionCompensationFund.id.length < 1 ||
    postulationForm.formOne.headTypeConnectionCompensationFund.id.trim() === ""
  ) {
    return true;
  }
  return false;
};

export const getInfoDocuments = () => {
  const dataInfo = {
    identityDocument: true,
    CertificateOfInactivityDependent: false,
    CertificateOfInactivityDependentError: false,
    CertificateOfInactivitySelfEmployed: false,
    CertificateOfInactivitySelfEmployedError: false,
    CertificatePermitsForMinors: false,
    CertificatePermitsForMinorsError: false,
  };

  const { postulationDocs, postulationForm } = useSelector(getPostulation);

  if (
    isObject(postulationForm.formOne.postulateMode) &&
    postulationForm.formOne.postulateMode.id != "" &&
    postulationForm.formOne.postulateMode.name === "Dependiente"
  ) {
    dataInfo.CertificateOfInactivityDependent = true;
  }

  if (
    isObject(postulationForm.formOne.postulateMode) &&
    postulationForm.formOne.postulateMode.id != "" &&
    postulationForm.formOne.postulateMode.name === "Independiente"
  ) {
    dataInfo.CertificateOfInactivitySelfEmployed = true;
  }
  if (getAge(postulationForm.formOne.headBirthDate) < Age.Adult) {
    dataInfo.CertificatePermitsForMinors = true;
  }
  return dataInfo;
};

export const getDocumentTypeByJob = (postulationForm: PostulationFormItem) => {
  if (
    isObject(postulationForm.formOne.postulateMode) &&
    postulationForm.formOne.postulateMode.id != "" &&
    postulationForm.formOne.postulateMode.name === "Dependiente"
  ) {
    return {
      name: "Certificación expedida",
      description:
        "certificación laboral expedida por tu último empleador, la cual debe contener (Causa de terminación de contrato, fecha de retiro y último salario devengado por el cual realizó el aporte a seguridad social la empresa) , para conocer el modelo de carta puedes dar clic Aquí",
      type: DocumentType["CertificateOfInactivityDependent"],
      url: "https://www.comfandi.com.co/sites/default/files/2024-05/Modelo certificación laboral para dependientes.pdf",
    };
  }

  if (
    isObject(postulationForm.formOne.postulateMode) &&
    postulationForm.formOne.postulateMode.id != "" &&
    postulationForm.formOne.postulateMode.name === "Independiente"
  ) {
    return {
      name: "Certificación juramentada",
      description:
        "declaración juramentada para trabajadores independientes, la cual puedes descargar de la pagina oficial de Comfandi dando clic Aquí",
      type: DocumentType["CertificateOfInactivitySelfEmployed"],
      url: "https://www.comfandi.com.co/sites/default/files/2022-09/Declaración juramentada cesante laboral para independientes.pdf",
    };
  }
};

export const emptyFormScheduling = (): FormSchedulingItem => ({
  name: "",
  lastName: "",
  tpDocument: { id: "", name: "" },
  numberIdentification: 0,
  phone: 0,
  alternativePhone: "",
  email: "",
  birthDate: "",
  age: 0,
  appointmentDate: "",
  gender: { id: "", name: "" },
  location: { id: "", name: "" },
  neighborhood: "",
  academicTraining: { id: "", name: "" },
  typeDisability: { id: "", name: "" },
  vulnerabilityCondition: { id: "", name: "" },
  psychologistId: "",
  hourDate: "",
  ethnicGroup: { id: "", name: "" },
  therapistConcept: "",
  residenceAddress: "",
  commune: { id: "", name: "" },
  stratum: { id: "", name: "" },
  stateEmployability: { id: "", name: "" },
  recordCreator: "",
  privacyConsent: false
});

export const calculateMonthsDifference = (targetDate: any) => {
  const selectedDate = new Date(targetDate);
  const currentDate = new Date();

  const remainingMonths = differenceInMonths(currentDate, selectedDate);
  console.log(remainingMonths, "esto son los meses");
  return remainingMonths;
};

export const getDocumentationType = (key: string): MasterItem | undefined => {
  const result = getApiDocumentType(key);
  return result;
};

export const formatCreateDto = (postulationForm: FormSchedulingItem) => {
  const appointmentTime = new Date(
    "2023-08-25T" + postulationForm.hourDate + ":00Z"
  );

  const data = {
    document_type_id: postulationForm.tpDocument.id,
    document: postulationForm.numberIdentification,
    name: postulationForm.name,
    lastname: postulationForm.lastName,
    telephone: postulationForm.phone,
    telephone_alt: postulationForm.alternativePhone,
    email: postulationForm.email,
    isMassAppointment: "NO",
    register_date: postulationForm.appointmentDate,
    date_hour_id: "",
    psychologist_id: postulationForm.psychologistId,
    birthDate: postulationForm.birthDate,
    age: Number(postulationForm.age),
    gender_id: postulationForm.gender.id,
    locality_id: postulationForm.location.id,
    neighborhood: postulationForm.neighborhood,
    educational_level_id: postulationForm.academicTraining.id,
    occupation_id: "",
    observation: "",
    resource_id: "87ca040c-b536-4531-a10b-4e90d0868536",
    state_id: "7581ccca-bfdb-4466-b4b0-a9b160344d95",
    disability_type_id: postulationForm.typeDisability.id,
    occupational_profile: "",
    vulnerability_condition_id: postulationForm.vulnerabilityCondition.id,
    workshops: "",
    formationFilter: null,
    formations: "",
    has_internet: "NO",
    has_computer: "NO",
    has_smartphone: "NO",
    hasEndFormation: "NO",
    judgment: "NO CUMPLE",
    hour_date: appointmentTime,
    ethnic_group_id: postulationForm.ethnicGroup.id,
    therapist_concept: "",
    residence_address: postulationForm.residenceAddress,
    commune: postulationForm.commune.name,
    stratum: postulationForm.stratum.name,
    state_employability: postulationForm.stateEmployability.name,
    appointment_type: "VIRTUAL",
    employment_agency_id: "",
    recordCreator: "Autogestion",
  };
  return data;
};
export const convertToCamelCase = (text: string) =>
  text.replace(/([a-z])([A-Z])/g, "$1_$2").toLowerCase();

export const emptyMenuAccordion = (): FormMenuAccordion => ({
  sectionOne: {
    id: 0,
    isVisible: false,
  },
  sectionTwo: {
    id: 0,
    isVisible: false,
  },
  sectionThree: {
    id: 0,
    isVisible: false,
  },
  sectionFour: {
    id: 0,
    isVisible: false,
  },
});

export const getShortPath = (path: string) => {
  const shorPath = path.split("/");
  return shorPath[2];
};

export const dataSideMenu = (): MasterItemSideMenu[] => [
  {
    id: "1",
    label: "Datos personales",
    path: "/steps/InformationPersonalOneNew",
    enabled: false,
  },
  {
    id: "2",
    label: "Información laboral",
    path: "/steps/InformationPersonalThreeNew",
    enabled: false,
  },
  {
    id: "3",
    label: "Información seguridad social",
    path: "/steps/SocialSecurityInformationNew",
    enabled: false,
  },
  {
    id: "4",
    label: "Resumen de mi solicitud",
    path: "/steps/ApplicationSummary",
    enabled: false,
  },
  {
    id: "5",
    label: "Verificación final",
    path: "/steps/FinalVerificationProcess",
    enabled: false,
  },
];

export const schedulingSideMenu = (): MasterItemSideMenu[] => [
  {
    id: "1",
    label: "Agendamiento",
    path: "/scheduling/InformationPersonalOne",
    enabled: true,
  },
];
export const emptyPostulationMode = (): PostulationMode => ({
  id: "",
  name: "",
});

export const formatTimeMaintance = (date: Date | null) => {
  const options: Intl.DateTimeFormatOptions = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };
  return date?.toLocaleTimeString("en-US", options);
};

export const emptyPreValidator = () => ({
  IdState: "",
  Description: "",
});
