import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  EducationalLevelFomentoItem,
  EducationalLevelFomentoState,
} from "@lib/store/types";

const initialState: EducationalLevelFomentoState = {
  dataEducationalLevelFomento: [],
  loading: true,
  error: "",
};

const educationalLevelFomentoSlice = createSlice({
  name: "EducationalLevels",
  initialState,
  reducers: {
    loadEducationalLevelsFomento: () => initialState,
    loadEducationalLevelsFomentoSuccess: (
      state,
      { payload }: PayloadAction<EducationalLevelFomentoItem[]>
    ) => ({
      ...state,
      dataEducationalLevelFomento: payload,
      loading: false,
    }),
    loadEducationalLevelsFomentoError: (
      state,
      { payload }: PayloadAction<string>
    ) => ({
      ...state,
      error: payload,
      loading: false,
    }),
  },
});

export const {
  loadEducationalLevelsFomento,
  loadEducationalLevelsFomentoSuccess,
  loadEducationalLevelsFomentoError,
} = educationalLevelFomentoSlice.actions;

export default educationalLevelFomentoSlice.reducer;
