import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MasterItem, MasterStateTpDocument } from "@lib/store/types/index";

const initialState: MasterStateTpDocument = {
  dataTpDocument: [],
  error: "",
  loading: true,
};

const typeDocumentsSlice = createSlice({
  name: "typeDocuments",
  initialState,
  reducers: {
    loadTypeDocuments: () => initialState,
    loadTypeDocumentsSuccess: (
      state,
      { payload }: PayloadAction<MasterItem[]>
    ) => ({
      ...state,
      dataTpDocument: payload,
      loading: false,
    }),
    loadTypeDocumentsError: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      loading: false,
      error: payload,
    }),
  },
});

export const {
  loadTypeDocuments,
  loadTypeDocumentsSuccess,
  loadTypeDocumentsError,
} = typeDocumentsSlice.actions;

export default typeDocumentsSlice.reducer;
