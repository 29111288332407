import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ValidationState, PostulationCheckList } from "../types";

const initialState: ValidationState = {
  postulationCheckList: [],
  error: "",
  loading: true,
};

const validationSlice = createSlice({
  name: "validation",
  initialState,
  reducers: {
    loadValidation: () => initialState,
    loadValidationSuccess: (
      state,
      { payload }: PayloadAction<Array<PostulationCheckList>>
    ) => ({
      ...state,
      postulationCheckList: payload,
      loading: false,
    }),
    loadValidationError: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      loading: false,
      error: payload,
    }),
  },
});

export const { loadValidation, loadValidationSuccess, loadValidationError } =
  validationSlice.actions;

export default validationSlice.reducer;
