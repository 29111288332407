import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MasterItem, MasterState } from "@lib/store/types";

const initialState: MasterState = {
  data: [],
  error: "",
  loading: true,
};

const mainRoadLetterSlice = createSlice({
  name: "mainRoad",
  initialState,
  reducers: {
    loadMainRoadLetter: () => initialState,
    loadMainRoadLetterSuccess: (
      state,
      { payload }: PayloadAction<MasterItem[]>
    ) => ({
      ...state,
      data: payload,
      loading: false,
    }),
    loadMainRoadLetterError: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      loading: false,
      error: payload,
    }),
  },
});

export const {
  loadMainRoadLetter,
  loadMainRoadLetterSuccess,
  loadMainRoadLetterError,
} = mainRoadLetterSlice.actions;

export default mainRoadLetterSlice.reducer;
