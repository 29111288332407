import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MasterItem, MasterStateCapturingLayoffs } from "@lib/store/types/index";

const initialState: MasterStateCapturingLayoffs = {
  dataCapturingLayoffs: [],
  error: "",
  loading: true,
};

const capturingLayoffsEntitySlice = createSlice({
  name: "capturingLayoffsEntity",
  initialState,
  reducers: {
    loadCapturingLayoffsEntityStatus: () => initialState,
    loadCapturingLayoffsEntitySuccess: (
      state,
      { payload }: PayloadAction<MasterItem[]>
    ) => ({
      ...state,
      dataCapturingLayoffs: payload,
      loading: false,
    }),
    loadCapturingLayoffsEntityError: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      loading: false,
      error: payload,
    }),
  },
});

export const { loadCapturingLayoffsEntityStatus, loadCapturingLayoffsEntitySuccess, loadCapturingLayoffsEntityError } =
capturingLayoffsEntitySlice.actions;

export default capturingLayoffsEntitySlice.reducer;
