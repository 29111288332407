import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DisabilityTypeItem, DisabilityTypeState } from "../types";

const initialState: DisabilityTypeState = {
  dataDisabilityType: [],
  loading: true,
};

const disabilityTypeSlice = createSlice({
  name: "DisabilityTypes",
  initialState,
  reducers: {
    loadDisabilityTypes: () => initialState,
    loadDisabilityTypesSuccess: (
      state,
      { payload }: PayloadAction<DisabilityTypeItem[]>
    ) => ({
      ...state,
      dataDisabilityType: payload,
      loading: false,
    }),
    loadDisabilityTypesError: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      loading: false,
      error: payload,
    }),
  },
});

export const {
  loadDisabilityTypes,
  loadDisabilityTypesSuccess,
  loadDisabilityTypesError,
} = disabilityTypeSlice.actions;

export default disabilityTypeSlice.reducer;
