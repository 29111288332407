import * as React from "react";
import "@comfanditd/chronux-ui/dist/style.css";
import "../styles/globals.css";
import "../styles/fonts.css";
import type { AppProps, AppContext } from "next/app";
import { Provider } from "react-redux";
import { store } from "@lib/store/store";
import cookie from "cookie";
import type { IncomingMessage } from "http";
import { SSRKeycloakProvider, SSRCookies } from "@react-keycloak-fork/ssr";
import { ProSidebarProvider } from "react-pro-sidebar";

// Configuración keycloak
const keycloakCfg = {
  realm: process.env.realm!,
  url: process.env.url!,
  clientId: process.env.clientId!,
};

interface InitialProps {
  cookies: unknown;
}

const initOptions = {
  checkLoginIframe: false,
  silentCheckSsoRedirectUri: false,
};

function App({ Component, pageProps, cookies }: AppProps & InitialProps) {
  return (
    <SSRKeycloakProvider
      keycloakConfig={keycloakCfg}
      persistor={SSRCookies(cookies)}
      initOptions={initOptions}
      onTokens={({ token }) => {
        localStorage.setItem("keycloakTokenRefresh", token!);
      }}
    >
      <Provider store={store}>
        <div>
          <ProSidebarProvider>
            <Component {...pageProps} />
          </ProSidebarProvider>
        </div>
      </Provider>
    </SSRKeycloakProvider>
  );
}

function parseCookies(req: IncomingMessage) {
  return cookie.parse(req.headers.cookie || "");
}

App.getInitialProps = async (context: AppContext) => {
  // Extract cookies from AppContext
  return {
    cookies: context.ctx.req ? parseCookies(context.ctx.req) : {},
  };
};

export default App;
