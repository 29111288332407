import { createSlice, current, PayloadAction } from "@reduxjs/toolkit";

import {
  UnemployedState,
  UnemployedItem,
  FormSchedulingItem,
  PostulationItem,
  UnemployedItemDB,
} from "@lib/store/types/index";
import { emptyFormScheduling } from "@lib/utils/helpers";

const initialState: UnemployedState = {
  registryId: "",
  unemployed: [],
  postulationForm: emptyFormScheduling(),
  workShop: [],
  formation: [],
  messageSuccess: false,
  messageError: false,
  messageIdError: false,
  messageHourDateError: false,
  messageDisponibilityError: false,
  messageSendPostulationError: false,
  loading: false,
  sending: false,
};

const unemployedSlice = createSlice({
  name: "unemployed",
  initialState,
  reducers: {
    loadUnemployed: () => initialState,
    loadUnemployedState: (
      state,
      { payload }: PayloadAction<UnemployedItem[]>
    ) => ({
      ...state,
      unemployed: payload,
      loading: true,
    }),
    loadUnemployedSuccess: (
      state,
      { payload }: PayloadAction<UnemployedItem[]>
    ) => ({
      ...state,
      unemployed: payload,
      loading: false,
    }),
    loadUnemployedError: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      error: payload,
      loading: false,
    }),
    saveScheduleAppointmentStepOne: (
      state,
      { payload }: PayloadAction<FormSchedulingItem>
    ) => ({
      ...state,
      postulationForm: payload,
      loading: true,
    }),
    sendingScheduleAppointmentSuccess: (
      state,
      { payload }: PayloadAction<{ data: UnemployedItem[]; registryId: string }>
    ) => ({
      ...state,
      sending: false,
      loading: false,
      unemployed: payload.data,
      registryId: payload.registryId,
    }),
    sendingScheduleAppointmentError: (
      state,
      { payload }: PayloadAction<string>
    ) => ({
      ...state,
      sending: false,
      loading: false,
      error: payload,
    }),

    loadUnemployedById: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      registryId: payload,
      loading: true,
    }),
    sendingUnemployedByIdSuccess: (
      state,
      {
        payload,
      }: PayloadAction<{
        data: UnemployedItem[];
        registryId: string;
        form: any;
      }>
    ) => ({
      ...state,
      unemployed: payload.data,
      registryId: payload.registryId,
      postulationForm: payload.form,
      sending: false,
      loading: false,
    }),
    updateRegisterUnemployed: (
      state,
      {
        payload,
      }: PayloadAction<{
        data: FormSchedulingItem;
        registryId: string;
      }>
    ) => ({
      ...state,
      postulationForm: payload.data,
      registryId: payload.registryId,
      sending: false,
      loading: true,
    }),
    loadMessageSuccess: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      messageSuccess: payload,
      loading: false,
    }),
    loadMessageError: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      messageError: payload,
      loading: false,
    }),
    loadMessageIdError: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      messageIdError: payload,
      loading: false,
    }),
    loadMessageHourDateValidation: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      messageHourDateError: payload,
      loading: false,
    }),
    loadMessageDisponibilityError: (
      state,
      { payload }: PayloadAction<boolean>
    ) => ({
      ...state,
      messageDisponibilityError: payload,
      loading: false,
    }),
    loadMessageSendPostulationError: (
      state,
      {payload}: PayloadAction<boolean>
    ) => ({
      ...state,
      messageSendPostulationError: payload,
      loading: false
    }),
    updateStateUnemployed: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      registryId: payload,
      sending: false,
      loading: true,
    }),

    loadUnemployedWithoutAttention: (
      state,
      { payload }: PayloadAction<string>
    ) => ({
      ...state,
      documentId: payload,
      loading: true,
    }),
    loadUnemployedWithoutAttentionSuccess: (
      state,
      { payload }: PayloadAction<UnemployedItemDB>
    ) => ({
      ...state,
      unemployedWithoutAttention: payload,
      loading: false,
    }),
    loadUnemployedWithoutAttentionError: (
      state,
      { payload }: PayloadAction<string>
    ) => ({
      ...state,
      loading: false,
      error: payload,
    }),
    cancelUnemployedWithoutAttention: (state) => ({
      ...state,
      sending: true,
    }),
    cancelUnemployedWithoutAttentionSuccess: (
      state,
      { payload }: PayloadAction
    ) => ({
      ...state,
      unemployedWithoutAttention: undefined,
      sending: false,
    }),
    cancelUnemployedWithoutAttentionError: (
      state,
      { payload }: PayloadAction<string>
    ) => ({
      ...state,
      sending: false,
      error: payload,
    }),
    loadUserTrainingWorkShopSuccess: (
      state,
      { payload }: PayloadAction<{ formation: []; workshop: [] }>
    ) => ({
      ...state,
      workShop: payload.workshop,
      formation: payload.formation,
      loading: false,
    }),
    loadUserTrainingWorkShopError: (
      state,
      { payload }: PayloadAction<string>
    ) => ({
      ...state,
      error: payload,
      loading: false,
    }),
  },
});
export const {
  loadUnemployed,
  loadUnemployedSuccess,
  loadUnemployedError,
  loadUnemployedState,
  saveScheduleAppointmentStepOne,
  sendingScheduleAppointmentSuccess,
  sendingScheduleAppointmentError,
  loadUnemployedById,
  sendingUnemployedByIdSuccess,
  updateRegisterUnemployed,
  loadMessageSuccess,
  loadMessageError,
  loadMessageIdError,
  loadMessageDisponibilityError,
  loadMessageSendPostulationError,
  updateStateUnemployed,
  loadUnemployedWithoutAttention,
  loadUnemployedWithoutAttentionSuccess,
  loadUnemployedWithoutAttentionError,
  cancelUnemployedWithoutAttention,
  cancelUnemployedWithoutAttentionSuccess,
  cancelUnemployedWithoutAttentionError,
  loadUserTrainingWorkShopSuccess,
  loadUserTrainingWorkShopError,
  loadMessageHourDateValidation,
} = unemployedSlice.actions;

export default unemployedSlice.reducer;
