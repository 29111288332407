import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MasterStateNavigation } from "../types";


const initialState: MasterStateNavigation = {
  loadingButton: false,
  disableButton: false
};

const navigationSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    loadNavigation: () => initialState,
    loadingSpinnerButton: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      loadingButton: payload,
      disableButton: payload,
    }),
  },
});

export const { loadNavigation, loadingSpinnerButton } = navigationSlice.actions;

export default navigationSlice.reducer;
