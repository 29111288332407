import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LocalityItem, LocalityState } from "../types";

const initialState: LocalityState = {
  dataLocality: [],
  loading: true,
};

const localitySlice = createSlice({
  name: "Localities",
  initialState,
  reducers: {
    loadLocalities: () => initialState,
    loadLocalitiesSuccess: (
      state,
      { payload }: PayloadAction<LocalityItem[]>
    ) => ({
      ...state,
      dataLocality: payload,
      loading: false,
    }),
    loadLocalitiesError: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      loading: false,
      error: payload,
    }),
  },
});

export const { loadLocalities, loadLocalitiesSuccess, loadLocalitiesError } =
  localitySlice.actions;

export default localitySlice.reducer;
