import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FormationItem, FormationState } from "@lib/store/types";

const initialState: FormationState = {
  dataFormation: [],
  loading: true,
};

const formationSlice = createSlice({
  name: "Formations",
  initialState,
  reducers: {
    loadFormations: () => initialState,
    loadFormationsSuccess: (
      state,
      { payload }: PayloadAction<FormationItem[]>
    ) => ({
      ...state,
      dataFormation: payload,
      loading: false,
    }),
    loadFormationsError: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      loading: false,
      error: payload,
    }),
  },
});

export const { loadFormations, loadFormationsSuccess, loadFormationsError } =
  formationSlice.actions;

export default formationSlice.reducer;
