import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MasterItem, MasterStatePostulationMode } from "@lib/store/types";

const initialState: MasterStatePostulationMode = {
  dataPostulationMode: [],
  error: "",
  loading: true,
};

const postulationModeSlice = createSlice({
  name: "postulationMode",
  initialState,
  reducers: {
    loadPostulationMode: () => initialState,
    loadPostulationModeSuccess: (state, { payload }: PayloadAction<MasterItem[]>) => ({
      ...state,
      dataPostulationMode: payload,
      loading: false,
    }),
    loadPostulationModeError: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      loading: false,
      error: payload,
    }),
  },
});

export const { loadPostulationMode, loadPostulationModeSuccess, loadPostulationModeError } =
  postulationModeSlice.actions;

export default postulationModeSlice.reducer;
